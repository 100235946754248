html, body {
  background-color: #f0f0f0;
  font-family: "Lora", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

ul, li {
  list-style: none;
}

#root {
  min-height: 100vh;
  padding-bottom: 2rem;
}

a {
  color: #2d3a3d;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  line-height: 1.2;
}

b {
  font-weight: 900;
}

input {
  outline: none;
}
.page-cases .page-2col > div:last-child {
  padding-top: 1rem;
}
.page-cases .ant-menu {
  margin-bottom: 1rem;
  flex: 0;
}

.case-list li a {
  margin-left: 1rem;
}
.case-list li:hover {
  background-color: #f5f5f5;
}
.case-list li.active {
  background-color: rgb(230, 244, 255);
}

.case-questions h5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.case-questions-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.case-questions-list li > :first-child {
  margin-right: auto;
}
.case-questions-list li .case-question-form {
  width: 100%;
}

.case-question-form .ant-select {
  min-width: 50%;
}

.case-groups-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.case-groups-list li > :first-child {
  margin-right: auto;
}
.case-groups-list li > .case-group-form:first-child {
  margin-right: 0;
  width: 100%;
}

.case-group-form {
  position: relative;
}
.case-group-form .ant-select {
  min-width: 50%;
}

.case-group-form-layout {
  display: flex;
  gap: 1rem;
}
.case-group-form-layout > div {
  flex: 1;
}
.case-group-form-layout > div:last-child {
  border-left: 1px solid #f5f5f5;
  padding-left: 1rem;
}

.case-group-form-close {
  position: absolute;
  top: 0;
  right: 0;
}

.case-article-form .ant-select {
  min-width: 50%;
}
.case-article-form textarea {
  min-height: 10rem;
}

.case-article-form-2col {
  display: flex;
  gap: 1rem;
}
.case-article-form-2col > div {
  flex: 1;
}

.case-articles-list li {
  display: flex;
  gap: 1rem;
}
.case-articles-list li > div:first-child {
  margin-right: auto;
}
.case-articles-list li .case-article-form {
  width: 100%;
}
.page-nouns .ant-menu {
  margin-bottom: 1rem;
}
.page-nouns .word-forms {
  display: flex;
  gap: 1rem;
}
.page-nouns .word-form {
  min-width: 25%;
}
.page-nouns .ant-select {
  width: 100%;
}
.page-nouns .word-case {
  display: flex;
  gap: 1rem;
  flex: 1;
}
.page-nouns .word-case > div:first-child {
  padding-right: 1rem;
  border-right: 1px solid #f5f5f5;
}
.page-nouns .case-examples {
  flex: 1;
}
.page-nouns .case-examples h5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-nouns .case-examples li {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}
.page-nouns .case-examples li > div:first-child {
  margin-right: auto;
}
.page-nouns .case-word {
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: flex-start;
  min-width: 0;
  margin-right: 0;
}

.auth-view {
  width: 30vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.auth-view a[href="#auth-sign-up"] {
  display: none;
}
.loading-view {
  width: 30rem;
  height: 30rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-view .icon {
  width: 5rem;
  height: 5rem;
  color: #cd6045;
}
.case-example-form > div {
  display: flex;
  gap: 1rem;
}
.case-example-form > div > div {
  flex: 1;
}
.items-list li a {
  margin-left: 1rem;
}
.items-list li:hover {
  background-color: #f5f5f5;
}
.items-list li.active {
  background-color: rgb(230, 244, 255);
}
.page {
  margin: 2rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}

.page-2col {
  display: flex;
  gap: 1rem;
}
.page-2col > div:first-child {
  flex: 0.2;
  min-width: 25%;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
  max-height: 75vh;
}
.page-2col > div:last-child {
  flex: 1;
  margin-right: 1rem;
}

.page-header {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
}
.page-header h1, .page-header h2, .page-header h3, .page-header h4, .page-header h5 {
  white-space: nowrap;
  margin-right: 1rem;
  margin-bottom: 0;
}
.page-header .ant-input {
  width: 15rem;
  margin-left: auto;
}
.top-menu {
  padding: 0rem 1rem;
  background-color: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}
.word-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}